import React from "react";
import { Paper, Grid, Button } from "@material-ui/core";

import "./assets/css/app.css";

const App: React.FC = () => {
  return (
    <div className="container">
      <Paper className="paperDiv">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item className="logosContainer">
            <a
              href="http://nilko.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./assets/logos/logoNilko.jpeg")}
                alt="Logo Nilko"
                className="logoNilko"
              />
            </a>
          </Grid>
          <Grid item style={{ marginTop: "1.5rem" }}>
            <a
              href="https://s3.us-east-2.amazonaws.com/lockyt.nilko/apk/app-release.apk"
              download
              className="linkDownload"
            >
              <Button
                variant="contained"
                color="primary"
                className="downloadBtn"
              >
                Download do app
              </Button>
            </a>
          </Grid>
          <Grid item style={{ marginTop: "2rem" }}>
            <a
              href="https://lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./assets/logos/logoPreto.png")}
                alt="Logo Lockyt"
                className="logoLockyt"
              />
            </a>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default App;
